import React, { useState } from "react";

import { Content, MenuItemGroup } from "./types";
import { DesktopNavigationSearch } from "../navigation-search";

interface DropDownGroupProps {
  menuGroup: MenuItemGroup;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

function menuSelection(
  _event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  selection: string,
  id: string | undefined,
): void {
  let analyticsCookie = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("analytics-cookies-accepted="))
    .map((c) => c.split("=")[1])[0];
  if (analyticsCookie) {
    let ga4DataLayer = window.dataLayer;
    ga4DataLayer.push({
      event: "UXevent",
      eCategory: "menu_click",
      eAction: "click",
      eLabel: selection,
    });
  }
  if (id === "joinRegionDropDownButton") {
    document
      ?.getElementById("join-dropdown-container")
      ?.classList.remove("dropdown-hidden");
    document
      ?.getElementById("background-overlay")
      ?.classList.remove("overlay-hidden");
  }
}

const DropDownGroup = ({ menuGroup }: DropDownGroupProps) => {
  const handleHover = () => {
    let getInTouchTitle = document.getElementsByClassName(
      "highlight-main-link",
    )[0];
    getInTouchTitle.classList.add("highlight-main-link-hover");
  };

  const handleHoverExit = () => {
    let getInTouchTitle = document.getElementsByClassName(
      "highlight-main-link",
    )[0];
    getInTouchTitle.classList.remove("highlight-main-link-hover");
  };

  const topLevelLink = menuGroup.length >= 1 && (
    <div
      className="main-link-container"
      onMouseEnter={
        menuGroup[0].title === "Get in touch" ? handleHover : undefined
      }
      onMouseLeave={
        menuGroup[0].title === "Get in touch" ? handleHoverExit : undefined
      }
    >
      <a
        href={menuGroup[0].url}
        onClick={(event) =>
          menuSelection(event, menuGroup[0].title, menuGroup[0].id)
        }
        className={
          menuGroup[0].title === "Get in touch" ? "highlight-main-link" : ""
        }
        id={menuGroup[0].id}
      >
        {menuGroup[0].title}
      </a>
    </div>
  );

  const dropdownContainer = menuGroup.length > 1 && (
    <div
      className="dropdown-container"
      // @ts-ignore
      onMouseEnter={
        menuGroup[0].title === "Get in touch" ? handleHover : undefined
      }
      // @ts-ignore
      onMouseLeave={
        menuGroup[0].title === "Get in touch" ? handleHoverExit : undefined
      }
    >
      <div className="dropdown-content">
        {menuGroup.slice(1).map((page, idx) => (
          <a
            href={page.url}
            onClick={(event) => menuSelection(event, page.title, page.id)}
            id={page.id}
            style={
              !page.bold_in_dropdown
                ? { fontWeight: "normal", fontSize: "15px" }
                : {}
            }
            key={`${page.url}-${idx}`}
          >
            {page.title}
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div className="flat-menu-bar-dropdown">
      {topLevelLink}
      {dropdownContainer}
    </div>
  );
};

interface SocialIconsProps {
  linkedinProfileUrl: string;
  twitterProfileUrl: string;
}

const SocialIcons: React.FC<SocialIconsProps> = ({
  linkedinProfileUrl,
  twitterProfileUrl,
}) => {
  return (
    <div className="header-social-icons">
      <a
        target="_blank"
        aria-label="LinkedIn"
        href={linkedinProfileUrl}
        rel="noreferrer"
      >
        <svg
          className="header-social-icon"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.5685 0H1.96076C0.877182 0 0 0.855068 0 1.90916V24.6201C0 25.6815 0.877182 26.5366 1.96076 26.5366H24.5758C25.652 26.5366 26.5366 25.6815 26.5366 24.6201V1.90916C26.5366 0.855068 25.652 0 24.5685 0Z"
            fill="#f9f3f0"
            className="linkedin-background"
          />
          <path
            className="green-hover-icon social-icon-path"
            d="M3.92922 9.95132H7.86548V22.6152H3.92922V9.95132ZM5.90472 3.65625C7.16521 3.65625 8.18245 4.68086 8.18245 5.93397C8.18245 7.19446 7.15784 8.21907 5.90472 8.21907C4.64424 8.21907 3.61963 7.19446 3.61963 5.93397C3.61963 4.67349 4.63686 3.65625 5.90472 3.65625Z"
            fill="#252E4B"
          />
          <path
            className="green-hover-icon social-icon-path"
            d="M10.3418 9.95022H14.1159V11.6825H14.1675C14.6908 10.6874 15.9734 9.64062 17.8899 9.64062C21.8704 9.64062 22.6075 12.2648 22.6075 15.6704V22.6143H18.6787V16.4592C18.6787 14.9922 18.6492 13.0978 16.6295 13.0978C14.5803 13.0978 14.2707 14.6974 14.2707 16.3486V22.6143H10.3418V9.95022Z"
            fill="#252E4B"
          />
        </svg>
      </a>
      <a
        target="_blank"
        aria-label="Twitter"
        href={twitterProfileUrl}
        rel="noreferrer"
      >
        <svg
          className="header-social-icon x-icon"
          width="21"
          height="21"
          viewBox="0 0 25 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
            className="green-hover-icon social-icon-path"
            fill="#252e4c"
          />
        </svg>
      </a>
    </div>
  );
};

const RegionDropDown = () => {
  const twitterUrl =
    document.querySelector('[aria-label="Twitter"]')?.getAttribute("href") ||
    "";
  const linkedinUrl =
    document.querySelector('[aria-label="LinkedIn"]')?.getAttribute("href") ||
    "";

  return (
    <div id="region-bar">
      <SocialIcons
        linkedinProfileUrl={linkedinUrl}
        twitterProfileUrl={twitterUrl}
      />
    </div>
  );
};

interface DesktopMenuProps {
  content: Content;
}

export const DesktopLodMenu = ({ content }: DesktopMenuProps) => {
  const menuItemsDesktop = content.menuItemsDesktop;
  const [searchExpanded, setSearchExpanded] = useState(false);

  return (
    <React.Fragment>
      <RegionDropDown />
      <div
        id="flat-menu-bar"
        className={searchExpanded ? "search-expanded" : ""}
      >
        {!searchExpanded &&
          menuItemsDesktop.map((menuGroup, idx) => (
            <DropDownGroup menuGroup={menuGroup} key={idx} />
          ))}
        <div className="navigation-search-container">
          <DesktopNavigationSearch
            searchExpanded={searchExpanded}
            setSearchExpanded={setSearchExpanded}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
