/* global GOOGLE_TAG_MANAGER_ID_ANALYTICAL, GOOGLE_TAG_MANAGER_ID_FUNCTIONAL, GOOGLE_TAG_MANAGER_ID_TARGETING */

function googleTagManagerRun(gtmTagId) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtmTagId);
}

export function googleTagManagerRunAnalytical() {
  googleTagManagerRun(GOOGLE_TAG_MANAGER_ID_ANALYTICAL);
}

export function googleTagManagerRunFunctional() {
  googleTagManagerRun(GOOGLE_TAG_MANAGER_ID_FUNCTIONAL);
}

export function googleTagManagerRunTargeting() {
  googleTagManagerRun(GOOGLE_TAG_MANAGER_ID_TARGETING);
}

export function googleTagManagerRunAll() {
  googleTagManagerRunAnalytical();
  googleTagManagerRunFunctional();
  googleTagManagerRunTargeting();
}
