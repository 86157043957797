import { Alert, Button, FormGroup, ModalRenderer } from "./misc";
import LocationInput from "^/components/common/location-input";
import store from "^/store";
import { Location } from "^/utils";
import classnames from "classnames";
import React, { ChangeEvent, ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import {
  Field,
  formValueSelector as createFormValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";

import { getErrorData, hasFailed, isPending } from "../utils/reduxRequests";
import { APPLY_TO_LOD, ApplyFormData, applyToLOD } from "^/actions";
import ErrorRenderer from "^/components/common/error-renderer";
import FileInput from "^/components/common/file-input";
import { StoreState, ThunkDispatch } from "^/store/types";
import RedirectModal from "./redirect-modal";

const FORM_NAME = "APPLY";

declare global {
  interface Window {
    renderApplyForm: (
      element: HTMLElement,
      thankYouPageUrl: string,
      locations: ReadonlyArray<Location>,
    ) => void;
  }
}

interface StateProps {
  loading: boolean;
  hasErrors: boolean;
  errors: any;
  fileNames: ReadonlyArray<string>;
  hasLocation: boolean;
  initialValues: {
    privacy_policy_consent: boolean;
    contact_consent: boolean;
  };
}

interface DispatchProps {
  onSubmit: (data: Partial<ApplyFormData>) => void;
}

interface OwnProps {
  thankYouPageUrl: string;
  locations: ReadonlyArray<Location>;
}

export type Props = InjectedFormProps<
  ApplyFormData,
  StateProps & DispatchProps & OwnProps
> &
  StateProps &
  DispatchProps &
  OwnProps;

interface State {
  modals: ReadonlyArray<ReactElement>;
}

export class ApplyForm extends React.PureComponent<Props, State> {
  public state = {
    modals: [],
  };

  public render() {
    const { loading, hasErrors, hasLocation, errors, fileNames, locations } =
      this.props;

    return (
      <form onSubmit={this.props.handleSubmit} className="form-align-right">
        <div className="margin-vertical-large">
          <div>
            <span className="float-right">* required</span>
            <h6 className="sans-serif">ABOUT YOU</h6>
          </div>
          <hr />

          <FormGroup>
            <label>Location*</label>
            <Field
              name="location"
              component={LocationInput}
              locations={locations}
              onChange={this.onChangeLocationRedirect}
              normalize={this.eraseRedirectValue}
            />
            <ErrorRenderer errors={errors} field="location" />
          </FormGroup>

          <FormGroup>
            <label>Full Name*</label>
            <Field
              name="full_name"
              component="input"
              type="text"
              disabled={loading || !hasLocation}
              className={classnames({
                error:
                  errors && errors.response && errors.response.data.full_name,
              })}
            />
            <ErrorRenderer errors={errors} field="full_name" />
          </FormGroup>

          <FormGroup>
            <label>Email Address*</label>
            <Field
              name="email"
              component="input"
              type="email"
              disabled={loading || !hasLocation}
              className={classnames({
                error: errors && errors.response && errors.response.data.email,
              })}
            />
            <ErrorRenderer errors={errors} field="email" />
          </FormGroup>

          <FormGroup>
            <label>Telephone*</label>
            <Field
              name="telephone"
              component="input"
              type="tel"
              disabled={loading || !hasLocation}
              className={classnames({
                error:
                  errors && errors.response && errors.response.data.telephone,
              })}
            />
            <ErrorRenderer errors={errors} field="telephone" />
          </FormGroup>
        </div>

        <div className="margin-vertical-large">
          <h6 className="sans-serif">PROFESSIONAL EXPERIENCE</h6>
          <hr />

          <FormGroup>
            <label className="label-align-top">Upload CV</label>
            <div
              className={classnames("simulate-input input-type-file", {
                error: errors && errors.response && errors.response.data.cv,
              })}
            >
              <label htmlFor="custom-file-upload" className="drop-area" />
              <Field
                name="cv"
                component={FileInput}
                type="file"
                disabled={loading || !hasLocation}
              />
              <p className="margin-bottom-small">Drag and drop your CV here</p>
              <p className="margin-none text-transform-uppercase font-size-x-small grey-medium">
                .PDF or .DOC accepted
              </p>
              {fileNames &&
                fileNames.map((name, idx) => (
                  <p
                    key={idx}
                    className="font-size-x-small margin-bottom-none grey-medium"
                  >
                    {name}
                  </p>
                ))}
              <label
                htmlFor="custom-file-upload"
                className={classnames("button hollow", {
                  disabled: !hasLocation,
                })}
              >
                Upload
              </label>
            </div>
            <ErrorRenderer errors={errors} field="cv" />
          </FormGroup>

          <FormGroup>
            <label>LinkedIn Profile URL</label>
            <Field
              name="linked_in_profile_url"
              component="input"
              type="url"
              disabled={!hasLocation}
              className={classnames({
                error:
                  errors &&
                  errors.response &&
                  errors.response.data.linked_in_profile_url,
              })}
            />
            <ErrorRenderer errors={errors} field="linked_in_profile_url" />
          </FormGroup>

          <FormGroup>
            <label>Current Notice Period</label>
            <Field
              name="current_notice_period"
              component="input"
              type="text"
              disabled={loading || !hasLocation}
              className={classnames({
                error:
                  errors &&
                  errors.response &&
                  errors.response.data.current_notice_period,
              })}
            />
            <ErrorRenderer errors={errors} field="current_notice_period" />
          </FormGroup>

          <FormGroup>
            <label className="label-align-top">Cover Note</label>
            <Field
              name="cover_note"
              component="textarea"
              disabled={loading || !hasLocation}
              className={classnames({
                error:
                  errors && errors.response && errors.response.data.cover_note,
              })}
            />
            <ErrorRenderer errors={errors} field="cover_note" />
          </FormGroup>
        </div>

        <div className="margin-vertical-x-large">
          <div>
            <span className="float-right grey-light">* required</span>
            <h6 className="sans-serif grey-medium text-transform-uppercase">
              The Legal Part
            </h6>
          </div>
          <hr className="section-break" />

          <FormGroup>
            <Field
              name="contact_consent"
              component="input"
              type="checkbox"
              disabled={loading}
            />
            <label className="margin-left-base">
              I'm happy for LOD to contact me with information about legal
              professionals, invitations to events and thought provoking
              content.
            </label>
            <ErrorRenderer errors={errors} field="contact_consent" />
          </FormGroup>

          <FormGroup>
            <Field
              name="privacy_policy_consent"
              component="input"
              type="checkbox"
              disabled={loading}
            />
            <label className="margin-left-base">
              I have read LOD's{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>{" "}
              and agree to processing of my personal data in line with it.*
            </label>
            <ErrorRenderer errors={errors} field="privacy_policy_consent" />
          </FormGroup>
        </div>

        <FormGroup>
          <ErrorRenderer errors={errors} field="non_field_errors" />
          {hasErrors && (
            <Alert className="error">
              <p>There was a problem submitting you data.</p>
            </Alert>
          )}
          <div className="form-button-group">
            <Button
              className="display-block"
              type="submit"
              disabled={loading || !hasLocation}
            >
              Send Application
            </Button>
          </div>
        </FormGroup>
        <div className="redirect-modal-wrapper">
          <ModalRenderer modals={this.state.modals} />
        </div>
      </form>
    );
  }

  private getMatchingLocation = (value: string) =>
    this.props.locations.find(({ location }) => location === value);

  private onChangeLocationRedirect = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    if (value) {
      const matchingLocation = this.getMatchingLocation(value);

      if (matchingLocation && matchingLocation.redirect) {
        this.setState({
          modals: [<RedirectModal key="" url={matchingLocation.redirect} />],
        });
      }
    }
  };

  private eraseRedirectValue = (value: string) => {
    const matchingLocation = this.getMatchingLocation(value);

    if (matchingLocation && matchingLocation.redirect) {
      return "";
    }

    return value;
  };
}

const formValueSelector = createFormValueSelector(FORM_NAME);

const FormifiedApplyForm = reduxForm<
  ApplyFormData,
  StateProps & DispatchProps & OwnProps
>({
  form: FORM_NAME,
})(ApplyForm);

function mapStateToProps(state: StoreState): StateProps {
  const files: FileList | undefined = formValueSelector(state, "cv");
  const location: string | undefined = formValueSelector(state, "location");
  const fileNames = (files ? [...files] : []).map((file) => file.name);

  return {
    loading: isPending(state.responses, APPLY_TO_LOD),
    hasErrors: hasFailed(state.responses, APPLY_TO_LOD),
    errors: getErrorData(state.responses, APPLY_TO_LOD),
    fileNames,
    hasLocation: Boolean(location),
    initialValues: {
      contact_consent: false,
      privacy_policy_consent: false,
    },
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch,
  props: OwnProps,
): DispatchProps {
  return {
    onSubmit: (data: Partial<ApplyFormData>) =>
      dispatch(applyToLOD(data, props.thankYouPageUrl)),
  };
}

const ConnectedApplyForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(FormifiedApplyForm));

window.renderApplyForm = (
  element: HTMLElement,
  thankYouPageUrl: string,
  locations: ReadonlyArray<Location>,
) => {
  createRoot(element!).render(
    <Provider store={store}>
      <ConnectedApplyForm
        thankYouPageUrl={thankYouPageUrl}
        locations={locations}
      />
    </Provider>,
  );
};
