import { getErrorData, hasFailed, isPending } from "../../utils/reduxRequests";
import { Button, Column, FormGroup, Row } from "../misc";
import {
  requestStayInformed,
  STAY_INFORMED,
  StayInformedData,
} from "^/actions";
import ErrorRenderer from "^/components/common/error-renderer";
import { StoreState, ThunkDispatch } from "^/store/types";
import { AxiosError } from "axios";
import React from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

declare global {
  interface Window {
    renderStayInformedForm: (
      element: HTMLElement,
      thankYouPageUrl: string,
    ) => void;
  }
}

interface StateProps {
  loading: boolean;
  hasErrors: boolean;
  errors: AxiosError | null;
  initialValues: {
    privacy_policy_consent: boolean;
    contact_consent: boolean;
  };
}

interface OwnProps {
  thankYouPageUrl: string;
}

export type Props = InjectedFormProps<StayInformedData, StateProps & OwnProps> &
  StateProps &
  OwnProps;

const StayInformedForm: React.FunctionComponent<Props> = ({
  errors,
  handleSubmit,
  loading,
}) => (
  <form onSubmit={handleSubmit} className="primary-darker">
    <Row className="newsletter-form-row">
      <Column xs={4}>
        <label>Full name*</label>
      </Column>

      <Column xs={8}>
        <Field
          className="full-width"
          name="full_name"
          component="input"
          type="text"
          disabled={loading}
        />
        <ErrorRenderer
          className="margin-top-small font-size-x-small text-transform-uppercase bold"
          errors={errors}
          field="full_name"
        />
      </Column>
    </Row>

    <Row className="newsletter-form-row">
      <Column xs={4}>
        <label>Email*</label>
      </Column>

      <Column xs={8}>
        <Field
          className="full-width"
          name="email"
          component="input"
          type="email"
          disabled={loading}
        />
        <ErrorRenderer
          className="margin-top-small font-size-x-small text-transform-uppercase bold"
          errors={errors}
          field="email"
        />
      </Column>
    </Row>

    <Row className="newsletter-form-row">
      <Column xs={4}>
        <label>Company*</label>
      </Column>

      <Column xs={8}>
        <Field
          className="full-width"
          name="company"
          component="input"
          type="text"
          disabled={loading}
        />
        <ErrorRenderer
          className="margin-top-small font-size-x-small text-transform-uppercase bold"
          errors={errors}
          field="company"
        />
      </Column>
    </Row>

    <Row className="newsletter-form-row">
      <Column xs={8} xsOffset={4}>
        <FormGroup className="margin-none">
          <Field
            name="contact_consent"
            component="input"
            type="checkbox"
            disabled={loading}
          />

          <label>
            I’m happy for LOD to contact me with invitations to events and
            thought provoking content.
          </label>

          <ErrorRenderer
            className="margin-top-small font-size-x-small text-transform-uppercase bold"
            errors={errors}
            field="contact_consent"
          />
        </FormGroup>
      </Column>
    </Row>

    <Row className="newsletter-form-row">
      <Column xs={8} xsOffset={4}>
        <FormGroup className="margin-none">
          <Field
            name="privacy_policy_consent"
            component="input"
            type="checkbox"
            disabled={loading}
          />

          <label>
            I confirm that I have read the{" "}
            <a className="bold" href="/privacy-policy" target="_blank">
              privacy policy
            </a>{" "}
            and I agree.
          </label>

          <ErrorRenderer
            className="margin-top-small font-size-x-small text-transform-uppercase bold"
            errors={errors}
            field="privacy_policy_consent"
          />
        </FormGroup>
      </Column>
    </Row>
    <div className="text-align-center margin-top-x-large">
      <Button type="submit" disabled={loading}>
        Keep me informed
      </Button>
    </div>
  </form>
);

const FormifiedStayInformedForm = reduxForm<
  StayInformedData,
  StateProps & OwnProps
>({
  form: "STAY_INFORMED_FORM",
})(StayInformedForm);

function mapStateToProps(state: StoreState): StateProps {
  return {
    loading: isPending(state.responses, STAY_INFORMED),
    hasErrors: hasFailed(state.responses, STAY_INFORMED),
    errors: getErrorData(state.responses, STAY_INFORMED),
    initialValues: {
      contact_consent: false,
      privacy_policy_consent: false,
    },
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch, props: OwnProps) {
  return {
    onSubmit: (data: Partial<StayInformedData>) =>
      dispatch(requestStayInformed(data, props.thankYouPageUrl)),
  };
}

const ConnectedStayInformedForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(FormifiedStayInformedForm));

export default ConnectedStayInformedForm;
