import { getErrorData, hasFailed, isPending } from "../utils/reduxRequests";
import { Alert, Button, FormGroup } from "./misc";
import TelephoneField from "^/components/telephone-field";
import store from "^/store";
import { AxiosError } from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {
  REQUEST_RESOURCE,
  requestResource,
  RequestResourceFormData,
} from "^/actions";
import ErrorRenderer from "^/components/common/error-renderer";
import LocationInput from "^/components/common/location-input";
import HowDidYouHearAboutUsInput from "^/components/common/how-did-you-hear-about-us-input";
import { StoreState, ThunkDispatch } from "^/store/types";
import { Location } from "^/utils";

declare global {
  interface Window {
    renderRequestResourceForm: (
      element: HTMLElement,
      thankYouPageUrl: string,
      locations: ReadonlyArray<Location>,
    ) => void;
  }
}

interface StateProps {
  loading: boolean;
  hasErrors: boolean;
  errors: AxiosError | null;
  initialValues: {
    privacy_policy_consent: boolean;
    contact_consent: boolean;
  };
}

interface OwnProps {
  thankYouPageUrl: string;
  locations: ReadonlyArray<Location>;
}

export type Props = InjectedFormProps<
  RequestResourceFormData,
  StateProps & OwnProps
> &
  StateProps &
  OwnProps;

export class RequestResourceForm extends React.PureComponent<Props> {
  public render() {
    const { loading, hasErrors, errors, locations } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit} className="form-align-right">
        <div className="margin-vertical-x-large">
          <div>
            <span className="float-right grey-light">* required</span>
            <h6 className="sans-serif grey-medium text-transform-uppercase">
              About You
            </h6>
          </div>
          <hr className="section-break" />

          <FormGroup>
            <label>Full name*</label>
            <Field
              name="full_name"
              component="input"
              type="text"
              disabled={loading}
            />
            <ErrorRenderer errors={errors} field="full_name" />
          </FormGroup>
          <FormGroup>
            <label>Email address*</label>
            <Field
              name="email"
              component="input"
              type="email"
              disabled={loading}
            />
            <ErrorRenderer errors={errors} field="email" />
          </FormGroup>
          <FormGroup className="telephone-number">
            <label>Telephone*</label>
            <Field
              component={TelephoneField}
              name="telephone"
              type="tel"
              disabled={loading}
            />
            <ErrorRenderer errors={errors} field="telephone" />
          </FormGroup>
          <FormGroup>
            <label>How did you hear about us?*</label>
            <Field
              name="how_did_you_hear_about_us"
              component={HowDidYouHearAboutUsInput}
              disabled={loading}
            />
            <ErrorRenderer errors={errors} field="location" />
          </FormGroup>
        </div>
        <div className="margin-vertical-x-large">
          <div>
            <span className="float-right grey-light">* required</span>
            <h6 className="sans-serif grey-medium text-transform-uppercase">
              Your Requirements
            </h6>
          </div>
          <hr className="section-break" />

          <FormGroup>
            <label>Location*</label>
            <Field
              name="location"
              component={LocationInput}
              locations={locations}
            />
            <ErrorRenderer errors={errors} field="location" />
          </FormGroup>

          <FormGroup>
            <label className="label-align-top">
              Tell us a little about your requirements*
            </label>
            <Field
              name="requirements"
              component="textarea"
              disabled={loading}
            />
            <ErrorRenderer errors={errors} field="requirements" />
          </FormGroup>
        </div>

        <div className="margin-vertical-x-large">
          <div>
            <span className="float-right grey-light">* required</span>
            <h6 className="sans-serif grey-medium text-transform-uppercase">
              The Legal Part
            </h6>
          </div>
          <hr className="section-break" />

          <FormGroup>
            <Field
              name="contact_consent"
              component="input"
              type="checkbox"
              disabled={loading}
            />
            <label className="margin-left-base">
              I'm happy for LOD to contact me with information about legal
              professionals, invitations to events and thought provoking
              content.
            </label>
            <ErrorRenderer errors={errors} field="contact_consent" />
          </FormGroup>

          <FormGroup>
            <Field
              name="privacy_policy_consent"
              component="input"
              type="checkbox"
              disabled={loading}
            />
            <label className="margin-left-base">
              I have read LOD's{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>{" "}
              and agree to processing of my personal data in line with it.*
            </label>
            <ErrorRenderer errors={errors} field="privacy_policy_consent" />
          </FormGroup>
        </div>
        <FormGroup>
          <ErrorRenderer errors={errors} field="non_field_errors" />
          {hasErrors && (
            <Alert className="error">
              <p>There was a problem submitting your data.</p>
            </Alert>
          )}
          <div className="form-button-group">
            <Button className="display-block" type="submit" disabled={loading}>
              Send Request
            </Button>
          </div>
        </FormGroup>
      </form>
    );
  }
}

const FormifiedRequestResourceForm = reduxForm<
  RequestResourceFormData,
  StateProps & OwnProps
>({
  form: "REQUEST_RESOURCE",
})(RequestResourceForm);

function mapStateToProps(state: StoreState): StateProps {
  return {
    loading: isPending(state.responses, REQUEST_RESOURCE),
    hasErrors: hasFailed(state.responses, REQUEST_RESOURCE),
    errors: getErrorData(state.responses, REQUEST_RESOURCE),
    initialValues: {
      contact_consent: false,
      privacy_policy_consent: false,
    },
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch, props: OwnProps) {
  return {
    onSubmit: (data: Partial<RequestResourceFormData>) =>
      dispatch(requestResource(data, props.thankYouPageUrl)),
  };
}

const ConnectedRequestResourceForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(FormifiedRequestResourceForm));

window.renderRequestResourceForm = (
  element: HTMLElement,
  thankYouPageUrl: string,
  locations: ReadonlyArray<Location>,
) => {
  createRoot(element!).render(
    <Provider store={store}>
      <ConnectedRequestResourceForm
        thankYouPageUrl={thankYouPageUrl}
        locations={locations}
      />
    </Provider>,
  );
};
