import { Button, Modal } from "../misc";
import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";

import {
  MobileNavigationSearch,
  MobileNavigationResponsiveSearch,
} from "../navigation-search";
import { toggleMenu } from "^/actions";
import MenuIcon from "^/components/menu-icon";
import TimesRegularIcon from "^/components/times-regular";
import { StoreState } from "^/store/types";
import MenuGroup from "./menu-group";
import { Content } from "./types";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function mobileMenuToggle(action: string) {
  let eAction = "";
  if (action === "open") {
    eAction = "open";
  } else {
    eAction = "close";
  }
  let analyticsCookie = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("analytics-cookies-accepted="))
    .map((c) => c.split("=")[1])[0];
  if (analyticsCookie == "true") {
    let ga4DataLayer = window.dataLayer;
    ga4DataLayer.push({
      event: "UXevent",
      eCategory: "menu_click",
      eAction: "mobile_click",
      eLabel: eAction,
    });
  }
}

declare global {
  interface Window {
    renderMobileMenu: (element: HTMLElement, content: Content) => void;
  }
}

export interface StateProps extends Pick<StoreState, "menuActive"> {
  menuActive: boolean;
}

export interface ContentProps {
  content: Content;
}

export interface State {
  modals: Array<React.ReactElement<typeof Modal>>;
  searchExpanded: boolean;
}

export interface DispatchProps {
  toggleMenu: typeof toggleMenu;
}

export type Props = ContentProps & StateProps & DispatchProps;

export interface MainMenuModalRendererProps
  extends React.HTMLProps<HTMLDivElement> {
  modals: ReadonlyArray<React.ReactNode>;
}

const MainMenuModalRenderer = React.memo(
  (props: MainMenuModalRendererProps) => {
    const { modals } = props;

    return (
      <React.Fragment>
        {modals.map((modal, idx) => (
          <div key={idx} className="modal-container">
            {modal}
          </div>
        ))}
      </React.Fragment>
    );
  },
);

const MAIN_MENU_ANIMATION_TIME_MS = 100;

export class LodMenu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modals: [],
      searchExpanded: false,
    };
  }

  public render() {
    const { modals } = this.state;
    const { menuActive } = this.props;

    return (
      <>
        <div className="navigation-search-container">
          <MobileNavigationResponsiveSearch />
        </div>
        <Button
          onClick={this.toggleModal}
          className={classnames("lod-menu-btn", {
            active: menuActive,
          })}
        >
          {menuActive ? <TimesRegularIcon /> : <MenuIcon className="" />}
        </Button>

        <div
          style={{
            opacity: menuActive ? 1 : 0,
            transition: `opacity ${MAIN_MENU_ANIMATION_TIME_MS / 1000}s`,
          }}
        >
          <MainMenuModalRenderer modals={modals} />
        </div>
      </>
    );
  }

  public toggleModal = () => {
    const { modals } = this.state;

    this.props.toggleMenu();

    if (modals.length) {
      this.onClickCloseModal();
    } else {
      this.onClickOpenModal();
    }
  };

  public onClickCloseModal = () => {
    mobileMenuToggle("close");
    setTimeout(() => {
      this.setState({
        modals: [],
      });
    }, MAIN_MENU_ANIMATION_TIME_MS);
  };

  public onClickOpenModal = () => {
    mobileMenuToggle("open");
    const {
      content: { menuItemsMobile, requestResource },
    } = this.props;

    const mainMenuModal = (
      <Modal key={1} onClickOutside={this.onClickCloseModal}>
        <div id="menu-bar">
          <div className="container-fluid">
            <div className="inner-header">
              <a href="/" className="lod-logo-modal">
                <img
                  className="lod-logo"
                  src={
                    document.querySelector(".lod-logo")?.getAttribute("src") ||
                    ""
                  }
                ></img>
              </a>

              {requestResource && (
                <a href={requestResource.url} className="main-menu-button">
                  <Button className="hollow bigger-padding">
                    {requestResource.title}
                  </Button>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="navigation-container">
          <div className="navigation-menu-list">
            <ul>
              {menuItemsMobile.map((menuGroup, index) => (
                <MenuGroup key={index} menuGroup={menuGroup} />
              ))}
            </ul>
            <MobileNavigationSearch />
          </div>
        </div>
      </Modal>
    );

    this.setState({
      modals: [mainMenuModal],
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  menuActive: state.menuActive,
});

export const MobileLodMenu = connect(mapStateToProps, {
  toggleMenu,
})(React.memo(LodMenu));
