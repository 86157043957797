import { Button, Modal, ModalBody, ModalHeader, ModalRenderer } from "../misc";
import StayInformed from "^/components/newsletter/stay-informed";
import TimesRegularIcon from "^/components/times-regular";
import store from "^/store";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

declare global {
  interface Window {
    renderNewsletter: (
      newsletterButton: HTMLElement,
      newsletterModalRoot: HTMLElement,
      thankYouPageUrl: string,
    ) => void;
  }
}

export interface State {
  modals: Array<React.ReactElement<typeof Modal>>;
}

export interface OwnProps {
  button: HTMLElement;
  thankYouPageUrl: string;
}

export type Props = OwnProps;

export class Newsletter extends React.PureComponent<Props, State> {
  public state: State = {
    modals: [],
  };

  public componentDidMount() {
    this.props.button.addEventListener("click", this.onClickOpenModal);
  }

  public componentWillUnmount() {
    this.props.button.addEventListener("click", this.onClickCloseModal);
  }

  public render() {
    return (
      <Provider store={store}>
        <ModalRenderer modals={this.state.modals} />
      </Provider>
    );
  }

  public onClickCloseModal = () => {
    this.setState({
      modals: [],
    });
  };

  public onClickOpenModal = () => {
    this.setState({
      modals: [
        <Modal key={1} onClickOutside={this.onClickCloseModal} small>
          <ModalHeader className="newsletter-modal-header">
            <span className="float-right">
              <Button
                className="lod-menu-btn active"
                onClick={this.onClickCloseModal}
              >
                <TimesRegularIcon />
              </Button>
              <div className="margin-top-base text-align-center font-size-x-small text-transform-uppercase">
                Close
              </div>
            </span>

            <h3 className="text-transform-capitalize">Stay informed</h3>
            <p className="margin-bottom-x-large">
              The LOD monthly newsletter covers everything you’d ever need to
              know about the work/life in the legal industry.
            </p>
          </ModalHeader>
          <ModalBody>
            <StayInformed thankYouPageUrl={this.props.thankYouPageUrl} />
          </ModalBody>
        </Modal>,
      ],
    });
  };
}

window.renderNewsletter = (
  newsletterButton: HTMLElement,
  newsletterModalRoot: HTMLElement,
  thankYouPageUrl: string,
) => {
  createRoot(newsletterModalRoot!).render(
    <Newsletter thankYouPageUrl={thankYouPageUrl} button={newsletterButton} />,
  );
};
