import React from "react";

import { MenuItem } from "./types";

interface OwnProps {
  menuItem: MenuItem;
  large?: boolean;
}

function MenuItem(props: OwnProps) {
  const { menuItem, large } = props;

  function menuSelection(
    _event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selection: string,
    id: string | undefined,
  ): void {
    let analyticsCookie = document.cookie
      .split("; ")
      .filter((row) => row.startsWith("analytics-cookies-accepted="))
      .map((c) => c.split("=")[1])[0];
    if (analyticsCookie) {
      let ga4DataLayer = window.dataLayer;
      ga4DataLayer.push({
        event: "UXevent",
        eCategory: "menu_click",
        eAction: "click",
        eLabel: selection,
      });
    }
    if (id === "joinRegionDropDownButton") {
      document
        ?.getElementById("join-dropdown-container")
        ?.classList.remove("dropdown-hidden");
      document
        ?.getElementById("background-overlay")
        ?.classList.remove("overlay-hidden");
    }
  }

  return (
    <li
      key={menuItem.url}
      className={
        "list-style-type-none" +
        (large
          ? " lod-medium-header-navigation"
          : " lod-small-header-navigation") +
        (menuItem.bold_in_dropdown ? " header-bold" : " header-normal")
      }
    >
      <a
        href={menuItem.url}
        id={menuItem.id}
        onClick={(event) => menuSelection(event, menuItem.title, menuItem.id)}
      >
        {menuItem.title}
      </a>
    </li>
  );
}

export { MenuItem };

export default MenuItem;
