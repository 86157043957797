import store from "^/store";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { MobileLodMenu } from "./mobile-menu";
import { SearchBar } from "../navigation-search";
import { DesktopLodMenu } from "./desktop-menu";
import { Content } from "./types";

declare global {
  interface Window {
    renderMobileMenu: (element: HTMLElement, content: Content) => void;
    renderDesktopMenu: (element: HTMLElement, content: Content) => void;
    renderSearchBar: (element: HTMLElement, content: Content) => void;
  }
}

window.renderMobileMenu = (element: HTMLElement, content: Content) => {
  createRoot(element!).render(
    <Provider store={store}>
      <MobileLodMenu content={content} />
    </Provider>,
  );
};

window.renderDesktopMenu = (element: HTMLElement, content: Content) => {
  createRoot(element!).render(<DesktopLodMenu content={content} />);
};

window.renderSearchBar = (element: HTMLElement) => {
  createRoot(element).render(
    <Provider store={store}>
      <SearchBar />
    </Provider>,
  );
};
