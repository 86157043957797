import {
  makeAsyncActionSet,
  request,
  requestWithConfig,
} from "../utils/reduxRequests";

import { ActionNoPayload } from "^/actions/types";
import { ThunkDispatch } from "^/store/types";
import { convertObjectToFormData } from "^/utils";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const toggleMenu = (): ActionNoPayload => ({
  type: TOGGLE_MENU,
});

export interface ApplyFormData {
  full_name: string;
  email: string;
  telephone: string;
  cv: FileList | null;
  linked_in_profile_url?: string;
  current_notice_period?: string;
  cover_note?: string;
}

export const APPLY_TO_LOD_URL = "/api/join/appy-to-lod/";

export const APPLY_TO_LOD = makeAsyncActionSet("APPLY_TO_LOD");
export const applyToLOD =
  (data: Partial<ApplyFormData>, thankYouPageUrl: string) =>
  (dispatch: ThunkDispatch) => {
    const formData = convertObjectToFormData({
      ...data,
      cv: data.cv ? data.cv[0] : null,
    });
    return requestWithConfig(
      APPLY_TO_LOD,
      {
        url: APPLY_TO_LOD_URL,
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      },
      { shouldRethrow: () => true },
    )(dispatch)
      .then(() => window.location.replace(thankYouPageUrl))
      .catch((error) => error);
  };

export interface RequestResourceFormData {
  full_name: string;
  email: string;
  telephone: string;
  location: string;
  requirements: string;
  contact_consent: boolean;
  privacy_policy_consent: boolean;
  how_did_you_hear_about_us: string;
}

export const REQUEST_RESOURCE_URL = "/api/resources/request/";

export const REQUEST_RESOURCE = makeAsyncActionSet("REQUEST_RESOURCE");
export const requestResource =
  (data: Partial<ApplyFormData>, thankYouPageUrl: string) =>
  (dispatch: ThunkDispatch) => {
    return request(REQUEST_RESOURCE, REQUEST_RESOURCE_URL, "POST", data, {
      shouldRethrow: () => true,
    })(dispatch)
      .then(() => window.location.replace(thankYouPageUrl))
      .catch((error) => error);
  };

export interface StayInformedData {
  full_name: string;
  email: string;
  company: string;
}

export const STAY_INFORMED_URL = "/api/newsletter/sign-up/";

export const STAY_INFORMED = makeAsyncActionSet("STAY_INFORMED");
export const requestStayInformed =
  (data: Partial<ApplyFormData>, thankYouPageUrl: string) =>
  (dispatch: ThunkDispatch) => {
    return request(STAY_INFORMED, STAY_INFORMED_URL, "POST", data, {
      shouldRethrow: () => true,
    })(dispatch)
      .then(() => window.location.replace(thankYouPageUrl))
      .catch((error) => error);
  };
