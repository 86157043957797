import React from "react";

import MenuItem from "./menu-item";
import { MenuItemGroup } from "./types";

export interface MenuGroupProps {
  menuGroup: MenuItemGroup;
}

function MenuItemGroup(props: MenuGroupProps) {
  const { menuGroup } = props;
  return (
    <div className="navigation-group">
      {menuGroup.map((menuItem, index) => (
        <MenuItem key={menuItem.url} menuItem={menuItem} large={index == 0} />
      ))}
    </div>
  );
}

export { MenuItemGroup };

export default MenuItemGroup;
