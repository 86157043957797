import React from "react";
import { WrappedFieldProps } from "redux-form";

import { groupLocations, Location } from "^/utils";

interface OwnProps {
  locations: ReadonlyArray<Location>;
}

type Props = OwnProps & WrappedFieldProps;

const LocationInput = (props: Props) => {
  const {
    input: { onChange, value },
    locations,
  } = props;
  const groupedLocations = groupLocations(locations);
  return (
    <select onChange={onChange} value={value}>
      <option value="">Select your location</option>
      {groupedLocations.map((group) => (
        <optgroup label={group.area} key={group.area}>
          {group.locations.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};

export { LocationInput };

export default React.memo(LocationInput);
