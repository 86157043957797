import React from "react";
import { WrappedFieldProps } from "redux-form";

type Props = WrappedFieldProps;

const HowDidYouHearAboutUsInput = (props: Props) => {
  const {
    input: { onChange, value },
  } = props;
  const options = [
    "Advertisement",
    "Comms/mailout",
    "External conference",
    "LOD event face to face",
    "LOD event virtual",
    "Media/article/podcast",
    "Referral - LOD team",
    "Referral - other",
    "Social media",
    "Website",
    "Other",
  ];
  return (
    <select onChange={onChange} value={value}>
      <option value="">Select</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      ;
    </select>
  );
};

export { HowDidYouHearAboutUsInput };

export default React.memo(HowDidYouHearAboutUsInput);
