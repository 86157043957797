import { Modal, ModalBody, ModalHeader } from "./misc";
import React, { PureComponent } from "react";

interface OwnProps {
  url: string;
}

type Props = OwnProps;

const noop = () => null;

export class RedirectModal extends PureComponent<Props> {
  public componentDidMount() {
    window.setTimeout(() => {
      window.location.assign(this.props.url);
    }, 2000);
  }

  public render() {
    return (
      <Modal onClickOutside={noop} small>
        <ModalHeader>
          <p className="bold white">Redirecting you to {this.props.url}</p>
        </ModalHeader>
        <ModalBody>
          <p>
            If you are not automatically redirected in 5 seconds please{" "}
            <a href={this.props.url}>click here</a>.
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default RedirectModal;
