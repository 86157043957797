import { responsesReducer } from "../utils/reduxRequests";
import * as actions from "^/actions";
import { Action } from "^/actions/types";
import { StoreState } from "^/store/types";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

function menuActive(state = false, action: Action<any, any>) {
  switch (action.type) {
    case actions.TOGGLE_MENU:
      return !state;
    default:
      return state;
  }
}

export default combineReducers<StoreState>({
  menuActive,
  form: formReducer,
  responses: responsesReducer,
});
