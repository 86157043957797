import React from "react";
import { createRoot } from "react-dom/client";

import { CookieBanner, CookieBannerPopUpResetPreferences } from "./content";

window.addEventListener("load", function () {
  const cookieBannerElement = document.getElementById(
    "cookie-banner-placeholder",
  );
  if (cookieBannerElement) {
    createRoot(cookieBannerElement!).render(<CookieBanner />);
  }

  const cookieBannerChangePreferencesContainer = document.getElementById(
    "cookie-change-preferences-react-container",
  );
  if (cookieBannerChangePreferencesContainer) {
    createRoot(cookieBannerChangePreferencesContainer!).render(
      <CookieBannerPopUpResetPreferences />,
    );
  }
});
