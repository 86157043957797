import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

declare type IntrinsicElementType =
  | "a"
  | "article"
  | "aside"
  | "blockquote"
  | "button"
  | "caption"
  | "code"
  | "dd"
  | "div"
  | "dl"
  | "dt"
  | "fieldset"
  | "figcaption"
  | "figure"
  | "footer"
  | "form"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "header"
  | "label"
  | "li"
  | "main"
  | "nav"
  | "ol"
  | "p"
  | "pre"
  | "section"
  | "span"
  | "strong"
  | "table"
  | "tbody"
  | "td"
  | "th"
  | "thead"
  | "tr"
  | "ul";
interface OptionalComponentPropAndHTMLAttributes
  extends React.HTMLAttributes<HTMLElement> {
  /**
   * Set the component to render a different element type.
   */
  component?: IntrinsicElementType;
}

type AlertProps = OptionalComponentPropAndHTMLAttributes;

/**
 * A component for applying various styles to text, ideal for info, success, and error messages.
 */
export const Alert = React.memo((props: AlertProps): React.ReactElement => {
  const {
    children,
    className,
    component: Component = "div",
    ...remainingProps
  } = props;

  return (
    <Component {...remainingProps} className={`alert ${className}`}>
      {children}
    </Component>
  );
});

type ButtonProps = {
  /**
   * Set the style `display: block;`.
   */
  block?: boolean;
  /**
   * Make the button large
   */
  large?: boolean;
  /**
   * Make the button small
   */
  small?: boolean;
} & React.ButtonHTMLAttributes<HTMLElement> &
  OptionalComponentPropAndHTMLAttributes;

/**
 * Used in place of a standard `button` tag, this component adds additional styles and effects.
 */
export const Button = React.memo((props: ButtonProps) => {
  const {
    children,
    className,
    block,
    large,
    small,
    component: Component = "button",
    ...remainingProps
  } = props;

  const myClassNames = [
    "button",
    block ? "block" : null,
    small ? "small" : null,
    large ? "large" : null,
    className,
  ];

  // Cast necessary otherwise types are too complex
  const CastComponent = Component as "button";

  return (
    <CastComponent
      {...remainingProps}
      className={myClassNames.filter((i) => i).join(" ")}
    >
      {children}
    </CastComponent>
  );
});

type FormGroupProps = {
  /**
   * Set the style `display: block;` with label above input.
   */
  block?: boolean;
  /**
   * Offset the input, select, etc as if there was a label to the left of it
   */
  noLabel?: boolean;
} & OptionalComponentPropAndHTMLAttributes;

/**
 * Used to group a label & form input (or select).
 */
export const FormGroup = React.memo((props: FormGroupProps) => {
  const {
    children,
    className,
    block,
    noLabel,
    component: Component = "div",
    ...remainingProps
  } = props;

  const myClassNames = [
    "form-group",
    block ? "block" : null,
    noLabel ? "no-label" : null,
    className,
  ];

  return (
    <Component
      {...remainingProps}
      className={myClassNames.filter((i) => i).join(" ")}
    >
      {children}
    </Component>
  );
});

interface ModalRendererProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Array of modals to be rendered.
   */
  modals: ReadonlyArray<React.ReactNode>;
}

const TIMEOUT = {
  appear: 300,
  enter: 300,
  exit: 200,
};

/**
 * Required to render modals.
 * Should be rendered in the root of your app.
 * See the [Modal](#modal) section for a full example.
 */
export const ModalRenderer = React.memo((props: ModalRendererProps) => {
  const { modals } = props;

  return (
    <TransitionGroup>
      {modals &&
        modals.map((modal, index) => (
          <CSSTransition
            key={index}
            classNames="modal-transition"
            timeout={TIMEOUT}
          >
            <div className="modal-container">{modal}</div>
          </CSSTransition>
        ))}
    </TransitionGroup>
  );
});

type ModalProps = {
  /**
   * Allows the `ModalBody` to be scrolled, rather than page.
   */
  scrollable?: boolean;
  /**
   * Reduced size `Modal` for alerts.
   */
  small?: boolean;
  /**
   * Fill a larger area of the screen equal to the `Container`.
   */
  large?: boolean;
  /**
   * Fill the entire screen.
   */
  fill?: boolean;
  /**
   * Callback to trigger when the user clicks outside of the `Modal`.
   */
  onClickOutside(event: React.MouseEvent<HTMLDivElement>): void;
} & OptionalComponentPropAndHTMLAttributes;

/**
 * Component used to render a modal.
 */
export const Modal = React.memo((props: ModalProps) => {
  const {
    className,
    children,
    onClickOutside,
    scrollable,
    small,
    large,
    fill,
    component: Component = "div",
    ...remainingProps
  } = props;

  const myClassNames = [
    "modal-position",
    scrollable ? "scrollable" : null,
    small ? "small" : null,
    large ? "large" : null,
    fill ? "fill" : null,
  ];

  return (
    <div>
      <div className="modal-overlay" onClick={onClickOutside} />
      <div
        {...remainingProps}
        className={myClassNames.filter((i) => i).join(" ")}
      >
        <Component className={`modal ${className}`}>{children}</Component>
      </div>
    </div>
  );
});

type ModalBodyProps = OptionalComponentPropAndHTMLAttributes;

/**
 * Used within a `Modal` to contain the main content.
 * See the [Modal](#modal) section for a full example.
 */
export const ModalBody = React.memo((props: ModalBodyProps) => {
  const {
    className,
    children,
    component: Component = "div",
    ...remainingProps
  } = props;

  return (
    <Component {...remainingProps} className={`modal-body ${className}`}>
      {children}
    </Component>
  );
});

type ModalHeaderProps = OptionalComponentPropAndHTMLAttributes;

/**
 * Header for `Modal`s to display a title.
 * See the [Modal](#modal) section for a full example.
 */
export const ModalHeader = React.memo((props: ModalHeaderProps) => {
  const {
    className,
    children,
    component: Component = "div",
    ...remainingProps
  } = props;

  return (
    <Component {...remainingProps} className={`modal-header ${className}`}>
      {children}
    </Component>
  );
});

const isValidColumnNumber = (value?: number): boolean =>
  typeof value === "number" && value === +value;

export type ColumnProps = {
  /**
   * Columns to occupy on extra small screens
   */
  xs?: number;
  /**
   * Columns to occupy on small screens
   */
  sm?: number;
  /**
   * Columns to occupy on medium screens
   */
  md?: number;
  /**
   * Columns to occupy on large screens
   */
  lg?: number;
  /**
   * Columns to occupy on extra large screens
   */
  xl?: number;
  /**
   * Columns to be offset by, with `margin-left`, on extra small screens
   */
  xsOffset?: number;
  /**
   * Columns to be offset by, with `margin-left`, on small screens
   */
  smOffset?: number;
  /**
   * Columns to be offset by, with `margin-left`, on medium screens
   */
  mdOffset?: number;
  /**
   * Columns to be offset by, with `margin-left`, on large screens
   */
  lgOffset?: number;
  /**
   * Columns to be offset by, with `margin-left`, on extra large screens
   */
  xlOffset?: number;
  /**
   * Columns to fill with `margin-right` on extra small screens
   */
  xsFill?: number;
  /**
   * Columns to fill with `margin-right` on small screens
   */
  smFill?: number;
  /**
   * Columns to fill with `margin-right` on medium screens
   */
  mdFill?: number;
  /**
   * Columns to fill with `margin-right` on large screens
   */
  lgFill?: number;
  /**
   * Columns to fill with `margin-right` on extra large screens
   */
  xlFill?: number;
  /**
   * Columns to be push left by, with `left`, on extra small screens
   */
  xsPush?: number;
  /**
   * Columns to be push left by, with `left`, on small screens
   */
  smPush?: number;
  /**
   * Columns to be push left by, with `left`, on medium screens
   */
  mdPush?: number;
  /**
   * Columns to be push left by, with `left`, on large screens
   */
  lgPush?: number;
  /**
   * Columns to be push left by, with `left`, on extra large screens
   */
  xlPush?: number;
  /**
   * Columns to be pulled left by, with `left`, on on extra small screens
   */
  xsPull?: number;
  /**
   * Columns to be pulled left by, with `left`, on on small screens
   */
  smPull?: number;
  /**
   * Columns to be pulled left by, with `left`, on on medium screens
   */
  mdPull?: number;
  /**
   * Columns to be pulled left by, with `left`, on on large screens
   */
  lgPull?: number;
  /**
   * Columns to be pulled left by, with `left`, on on extra large screens
   */
  xlPull?: number;
} & OptionalComponentPropAndHTMLAttributes;

/**
 * Placed inside rows to align content in columns.
 * The default grid has 12 divisions.
 */
export const Column = React.memo((props: ColumnProps) => {
  const {
    children,
    className,
    component: Component = "div",
    xs,
    sm,
    md,
    lg,
    xl,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    xsFill,
    smFill,
    mdFill,
    lgFill,
    xlFill,
    xsPush,
    smPush,
    mdPush,
    lgPush,
    xlPush,
    xsPull,
    smPull,
    mdPull,
    lgPull,
    xlPull,
    ...remainingProps
  } = props;

  const myClassNames = [
    "column",
    isValidColumnNumber(xs) ? `xs-${xs}` : null,
    isValidColumnNumber(sm) ? `sm-${sm}` : null,
    isValidColumnNumber(md) ? `md-${md}` : null,
    isValidColumnNumber(lg) ? `lg-${lg}` : null,
    isValidColumnNumber(xl) ? `xl-${xl}` : null,
    isValidColumnNumber(xsOffset) ? `xs-offset-${xsOffset}` : null,
    isValidColumnNumber(smOffset) ? `sm-offset-${smOffset}` : null,
    isValidColumnNumber(mdOffset) ? `md-offset-${mdOffset}` : null,
    isValidColumnNumber(lgOffset) ? `lg-offset-${lgOffset}` : null,
    isValidColumnNumber(xlOffset) ? `xl-offset-${xlOffset}` : null,
    isValidColumnNumber(xsFill) ? `xs-fill-${xsFill}` : null,
    isValidColumnNumber(smFill) ? `sm-fill-${smFill}` : null,
    isValidColumnNumber(mdFill) ? `md-fill-${mdFill}` : null,
    isValidColumnNumber(lgFill) ? `lg-fill-${lgFill}` : null,
    isValidColumnNumber(xlFill) ? `xl-fill-${xlFill}` : null,
    isValidColumnNumber(xsPush) ? `xs-push-${xsPush}` : null,
    isValidColumnNumber(smPush) ? `sm-push-${smPush}` : null,
    isValidColumnNumber(mdPush) ? `md-push-${mdPush}` : null,
    isValidColumnNumber(lgPush) ? `lg-push-${lgPush}` : null,
    isValidColumnNumber(xlPush) ? `xl-push-${xlPush}` : null,
    isValidColumnNumber(xsPull) ? `xs-pull-${xsPull}` : null,
    isValidColumnNumber(smPull) ? `sm-pull-${smPull}` : null,
    isValidColumnNumber(mdPull) ? `md-pull-${mdPull}` : null,
    isValidColumnNumber(lgPull) ? `lg-pull-${lgPull}` : null,
    isValidColumnNumber(xlPull) ? `xl-pull-${xlPull}` : null,
    className,
  ];

  return (
    <Component
      {...remainingProps}
      className={myClassNames.filter((i) => i).join(" ")}
    >
      {children}
    </Component>
  );
});

type RowProps = OptionalComponentPropAndHTMLAttributes;

/**
 * Used within a container, section, or column, to keep content on separate rows.
 * Row exists to allow columns to sit within a container / other column and be
 * spaced from each other without adding additional padding to the outsides.
 * It does this by using negative margin left and right. Additionally Row has
 * a clearfix applied which allows floated elements to be placed inside it
 * without it collapsing.
 */
export const Row = React.memo((props: RowProps) => {
  const {
    children,
    className,
    component: Component = "div",
    ...remainingProps
  } = props;

  return (
    <Component {...remainingProps} className={`row ${className}`}>
      {children}
    </Component>
  );
});
