import * as React from "react";

export interface Props {
  className: string;
}

const MenuIcon = ({ className }: Props) => (
  <svg className={className} width="35px" height="35px" viewBox="0 0 22 18">
    <path d="M0,2 L20,2" strokeWidth="3" />
    <path d="M0,9 L20,9" strokeWidth="3" />
    <path d="M0,16 L20,16" strokeWidth="3" />
  </svg>
);

export default MenuIcon;
