/* types */

import Cookies from "js-cookie";
import * as path from "path";
import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Dispatch } from "redux";
import { isFSA } from "flux-standard-action";
import { AnyAction } from "redux";

export type Dict<T> = Readonly<{ [key: string]: T }>;

export type RequestStates = "REQUEST" | "SUCCESS" | "FAILURE";
export type UrlMethod = "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS" | "PATCH";

export type ExtraMeta = Dict<any>;

export type AsyncActionSet = Readonly<{
  FAILURE: string;
  REQUEST: string;
  SUCCESS: string;
}>;

export type ResponseState<T = {}> = Readonly<{
  requestState: RequestStates | null;
  data: AxiosResponse<T> | AxiosError | null;
}>;

export type ResponsesReducerState<T = any> = Dict<Dict<ResponseState<T>>>;

export type SetRequestStatePayload = Readonly<{
  actionSet: AsyncActionSet;
  requestState: RequestStates;
  data: any;
  tag?: string;
}>;
export type ResetRequestStatePayload = Readonly<{
  actionSet: AsyncActionSet;
  tag?: string;
}>;

export interface Options {
  readonly tag?: string;
  shouldRethrow?(errors: AxiosError): boolean;
}

export interface RequestParams extends Options {
  readonly metaData?: ExtraMeta;
  readonly headers?: Dict<string>;
}

/* utils */

export function makeAsyncActionSet(actionName: string): AsyncActionSet {
  return {
    FAILURE: actionName + "_FAILURE",
    REQUEST: actionName + "_REQUEST",
    SUCCESS: actionName + "_SUCCESS",
  };
}

export function apiRequest<T = {}>(
  options: AxiosRequestConfig,
): AxiosPromise<T> {
  const combinedHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "X-CSRFToken": Cookies.get("csrftoken") || false,
    ...options.headers,
  };

  const url = options.url;
  let myPath;
  if (url) {
    if (url.split(/:\/\//).length > 1) {
      myPath = url;
    } else {
      myPath = path.normalize(url);
    }
  }

  const config = {
    ...options,
    url: myPath,
    headers: combinedHeaders,
    data: options.data || {},
  };

  // Axios uses a different key for sending data on a GET request
  if (options.method === "GET") {
    const { data, ...getConfig } = config;
    return axios({
      ...getConfig,
      params: data,
    });
  }
  return axios(config);
}

function getResponseState<T>(
  state: ResponsesReducerState<T>,
  actionSet: AsyncActionSet,
  tag?: string,
): ResponseState<T> {
  return (state[actionSet.REQUEST] || {})[tag || ""] || {};
}
export function isPending<T>(
  state: ResponsesReducerState<T>,
  actionSet: AsyncActionSet,
  tag?: string,
): boolean {
  return getResponseState(state, actionSet, tag).requestState === "REQUEST";
}

export function hasFailed<T>(
  state: ResponsesReducerState<T>,
  actionSet: AsyncActionSet,
  tag?: string,
): boolean {
  return getResponseState(state, actionSet, tag).requestState === "FAILURE";
}

export function hasSucceeded<T>(
  state: ResponsesReducerState<T>,
  actionSet: AsyncActionSet,
  tag?: string,
): boolean {
  return getResponseState(state, actionSet, tag).requestState === "SUCCESS";
}

export function anyPending<T>(
  state: ResponsesReducerState<T>,
  actionSets: ReadonlyArray<AsyncActionSet | [AsyncActionSet, string]>,
): boolean {
  return actionSets.some((actionSet) => {
    if (actionSet instanceof Array) {
      const [actualSet, tag] = actionSet;
      return isPending(state, actualSet, tag);
    } else {
      return isPending(state, actionSet);
    }
  });
}

function isAxiosError(data: Dict<any>): data is AxiosError {
  return "config" in data && "name" in data && "message" in data;
}

export function getErrorData<T>(
  state: ResponsesReducerState<T>,
  actionSet: AsyncActionSet,
  tag?: string,
): AxiosError | null {
  if (hasFailed(state, actionSet, tag)) {
    const responseState = getResponseState(state, actionSet, tag);
    if (responseState.data && isAxiosError(responseState.data)) {
      return responseState.data;
    }
  }
  return null;
}

/* actions */

export const REQUEST_STATE = "REQUEST_STATE";
export function setRequestState(
  actionSet: AsyncActionSet,
  requestState: RequestStates,
  data: any,
  tag = "",
) {
  return {
    payload: {
      actionSet,
      data,
      requestState,
      tag,
    },
    type: REQUEST_STATE,
  };
}

export const RESET_REQUEST_STATE = "RESET_REQUEST_STATE";
export function resetRequestState(actionSet: AsyncActionSet, tag = "") {
  return {
    payload: {
      actionSet,
      tag,
    },
    type: RESET_REQUEST_STATE,
  };
}

function serializeMeta(meta: Partial<ExtraMeta>, options: Options): ExtraMeta {
  return {
    ...meta,
    tag: options.tag || "",
  };
}

export function requestWithConfig<T = {}>(
  actionSet: AsyncActionSet,
  axoisConfig: AxiosRequestConfig,
  options: Options = {},
  extraMeta: Partial<ExtraMeta> = {},
) {
  return (dispatch: Dispatch<any>) => {
    const meta = serializeMeta(extraMeta, options);

    dispatch({ type: actionSet.REQUEST, meta });
    dispatch(setRequestState(actionSet, "REQUEST", null, meta.tag));

    return apiRequest<T>(axoisConfig).then(
      (response: AxiosResponse<T>) => {
        dispatch({
          type: actionSet.SUCCESS,
          payload: response,
          meta,
        });
        dispatch(setRequestState(actionSet, "SUCCESS", response, meta.tag));
        return response;
      },
      (error: AxiosError) => {
        const { shouldRethrow } = options;

        dispatch({
          type: actionSet.FAILURE,
          payload: error,
          meta,
          error: true,
        });
        dispatch(setRequestState(actionSet, "FAILURE", error, meta.tag));

        if (shouldRethrow && shouldRethrow(error)) {
          return Promise.reject(error);
        }

        return Promise.resolve();
      },
    );
  };
}

export function request<T = {}>(
  actionSet: AsyncActionSet,
  url: string,
  method: UrlMethod,
  data?: string | number | Dict<any> | ReadonlyArray<any>,
  params: RequestParams = {},
) {
  const { headers, tag, metaData, shouldRethrow } = params;
  return requestWithConfig<T>(
    actionSet,
    { url, method, data, headers },
    { tag, shouldRethrow },
    metaData,
  );
}

/* reducers */

export function responsesReducer<T = any>(
  state: ResponsesReducerState<T> = {},
  action: AnyAction,
): ResponsesReducerState<T> {
  switch (action.type) {
    case REQUEST_STATE:
      if (isFSA(action)) {
        const { actionSet, requestState, tag, data } =
          action.payload as unknown as SetRequestStatePayload;
        const existing = state[actionSet.REQUEST] || {};
        return {
          ...state,
          [actionSet.REQUEST]: {
            ...existing,
            [tag || ""]: {
              requestState,
              data,
            },
          },
        };
      }
      break;
    case RESET_REQUEST_STATE:
      if (isFSA(action)) {
        const { actionSet, tag } =
          action.payload as unknown as ResetRequestStatePayload;
        const existing = state[actionSet.REQUEST] || {};
        return {
          ...state,
          [actionSet.REQUEST]: {
            ...existing,
            [tag || ""]: {
              requestState: null,
              data: null,
            },
          },
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
