import * as cookie from "cookie";
import React, { Dispatch, SetStateAction, useState } from "react";

import {
  googleTagManagerRunAnalytical,
  googleTagManagerRunFunctional,
  googleTagManagerRunTargeting,
  googleTagManagerRunAll,
  // @ts-ignore
} from "./google-analytics.js";

import { addUtmQuerystringsToLocalStorage } from "^/utmTracking";

const setCookie = (key: string, value: string) => {
  document.cookie = cookie.serialize(key, value, {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    path: "/",
  });
};

const deleteCookie = (key: string) => {
  document.cookie = cookie.serialize(key, "", {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    path: "/",
  });
};

interface ToggleSwitchProps {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  label: string;
  locked?: boolean;
}

const ToggleSwitch = ({
  checked,
  setChecked,
  label,
  locked = false,
}: ToggleSwitchProps) => (
  <div className="cookie-type-container">
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <span
        className={"slider round" + (locked ? " locked" : " unlocked")}
      ></span>
    </label>
    {label}
  </div>
);

interface CookieBannerPopUpProps {
  divClasses: string;
  closeBanner: () => void;
  rejectAllAndClose: () => void;
  acceptAllAndClose: () => void;
  dismissed: boolean;
  getCurrentPreferences?: boolean;
}

const CookieBannerPopUp = ({
  divClasses,
  closeBanner,
  acceptAllAndClose,
  dismissed,
  getCurrentPreferences = false,
}: CookieBannerPopUpProps) => {
  const analyticsDefault = getCurrentPreferences
    ? cookie.parse(document.cookie)["analytics-cookies-accepted"] == "true"
    : false;
  const [analytics, setAnalytics] = useState(analyticsDefault);

  const functionalDefault = getCurrentPreferences
    ? cookie.parse(document.cookie)["functional-cookies-accepted"] == "true"
    : false;
  const [functional, setFunctional] = useState(functionalDefault);

  const targetingDefault = getCurrentPreferences
    ? cookie.parse(document.cookie)["targeting-cookies-accepted"] == "true"
    : false;
  const [targeting, setTargeting] = useState(targetingDefault);

  const acceptPreferences = () => {
    if (analytics) {
      setCookie("analytics-cookies-accepted", "true");
      googleTagManagerRunAnalytical();
    } else {
      deleteCookie("analytics-cookies-accepted");
    }
    if (functional) {
      setCookie("functional-cookies-accepted", "true");
      googleTagManagerRunFunctional();
    } else {
      deleteCookie("functional-cookies-accepted");
    }
    if (targeting) {
      setCookie("targeting-cookies-accepted", "true");
      googleTagManagerRunTargeting();
    } else {
      deleteCookie("targeting-cookies-accepted");
    }
    closeBanner();
  };

  return (
    <div
      className={`translucent-cookie-background ${
        !dismissed ? "open" : "closed"
      }`}
    >
      <div className={divClasses + "manage-cookies-pop-up"}>
        <ToggleSwitch
          checked={analytics}
          setChecked={setAnalytics}
          label={"Analytical cookies"}
        />
        <ToggleSwitch
          checked={functional}
          setChecked={setFunctional}
          label={"Functional cookies"}
        />
        <ToggleSwitch
          checked={targeting}
          setChecked={setTargeting}
          label={"Targeting cookies"}
        />
        <ToggleSwitch
          checked={true}
          setChecked={(x) => ({ x })}
          label={"Essential cookies (always enabled)"}
          locked={true}
        />
        <div className="read-more-box">
          To learn more about our cookies,{" "}
          <a href="/cookie-policy" target="_blank" className="bold">
            click here
          </a>
          .
        </div>
        <div className="cookie-button-container">
          <button
            onClick={acceptPreferences}
            className="cookie-banner-button manage-button"
          >
            Save preferences
          </button>
          <button
            onClick={acceptAllAndClose}
            className="cookie-banner-button accept-button"
          >
            Accept all
          </button>
        </div>
      </div>
    </div>
  );
};

interface CookieBannerMainContentProps {
  clickReject(): void;
  clickAccept(): void;
  clickManage(): void;
  divClasses: string;
  dismissed: boolean;
}

const CookieBannerMainContent = ({
  clickReject,
  clickAccept,
  clickManage,
  divClasses,
  dismissed,
}: CookieBannerMainContentProps) => {
  return (
    <div
      className={`translucent-cookie-background ${
        !dismissed ? "open" : "closed"
      }`}
    >
      <div className={divClasses + "main-cookie-pop-up"}>
        <p>
          We use cookies on our website. Full details are set out in our{" "}
          <a href="/cookie-policy" target="_blank">
            <b>cookie notice</b>
          </a>
          . Essential cookies are always on. To accept all cookies, including
          analytical, functional and targeting cookies, click “Accept”.
          Otherwise, click “Manage cookies” to manage your preferences.
        </p>
        <div className="cookie-button-container">
          <button
            onClick={clickManage}
            className="cookie-banner-button manage-button"
          >
            Manage cookies
          </button>

          <button
            onClick={clickReject}
            className="cookie-banner-button manage-button"
          >
            Reject
          </button>

          <button
            onClick={clickAccept}
            className="cookie-banner-button accept-button"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export const CookieBanner = () => {
  const [dismissed, setDismissed] = useState(
    cookie.parse(document.cookie)["cookies-set"] == "true",
  );
  const [showPopUp, setShowPopUp] = useState(false);

  const displayManageCookiesPopUp = () => {
    setShowPopUp(true);
  };

  const closeBanner = () => {
    setDismissed(true);
    setShowPopUp(false);
    addUtmQuerystringsToLocalStorage();
    setCookie("cookies-set", "true");
  };
  const acceptAllAndClose = () => {
    setCookie("analytics-cookies-accepted", "true");
    setCookie("functional-cookies-accepted", "true");
    setCookie("targeting-cookies-accepted", "true");
    googleTagManagerRunAll();
    closeBanner();
  };
  const rejectAllAndClose = () => {
    closeBanner();
  };

  const divClasses =
    "block-section cookie-pop-up " + (!dismissed ? "open " : "closed ");
  if (showPopUp) {
    return (
      <CookieBannerPopUp
        rejectAllAndClose={rejectAllAndClose}
        divClasses={divClasses}
        closeBanner={closeBanner}
        acceptAllAndClose={acceptAllAndClose}
        dismissed={dismissed}
      />
    );
  } else {
    return (
      <CookieBannerMainContent
        clickManage={displayManageCookiesPopUp}
        clickReject={rejectAllAndClose}
        clickAccept={acceptAllAndClose}
        divClasses={divClasses}
        dismissed={dismissed}
      />
    );
  }
};

export const CookieBannerPopUpResetPreferences = () => {
  const [dismissed, setDismissed] = useState(true);

  const closeBanner = () => {
    setDismissed(true);
    setCookie("cookies-set", "true");
  };

  const acceptAllAndClose = () => {
    setCookie("analytics-cookies-accepted", "true");
    setCookie("functional-cookies-accepted", "true");
    setCookie("targeting-cookies-accepted", "true");
    closeBanner();
  };

  const rejectAllAndClose = () => {
    closeBanner();
  };

  const divClasses =
    "block-section cookie-pop-up " + (!dismissed ? "open " : "closed ");

  return (
    <p style={{ paddingTop: "20px" }}>
      To modify your cookie preferences,{" "}
      <a
        onClick={() => {
          setDismissed(false);
        }}
      >
        click here.
      </a>
      {!dismissed && (
        <CookieBannerPopUp
          divClasses={divClasses}
          closeBanner={closeBanner}
          rejectAllAndClose={rejectAllAndClose}
          acceptAllAndClose={acceptAllAndClose}
          getCurrentPreferences={true}
          dismissed={dismissed}
        />
      )}
    </p>
  );
};
