import React, { Dispatch } from "react";
import { CloseSearchIcon, SearchRegularIcon } from "./search-icons";

interface NavigationSearchProps {
  searchExpanded: boolean;
  setSearchExpanded: Dispatch<boolean>;
}

const SearchTextBox = () => (
  <input
    type="text"
    name="q"
    placeholder="Enter your search term"
    title="Search for:"
  />
);

const SearchIcon = ({
  isFormSubmit,
  onClickFunction,
}: {
  isFormSubmit?: boolean;
  onClickFunction?: () => void;
}) => {
  const searchIconInner = (
    <div className="search-icon" onClick={onClickFunction}>
      <SearchRegularIcon />
    </div>
  );

  return isFormSubmit ? (
    <button type="submit">{searchIconInner}</button>
  ) : (
    <>{searchIconInner}</>
  );
};

const CloseIcon = ({ onClickFunction }: { onClickFunction: () => void }) => (
  <div className="search-icon" onClick={onClickFunction}>
    <CloseSearchIcon />
  </div>
);

export const DesktopNavigationSearch = ({
  searchExpanded,
  setSearchExpanded,
}: NavigationSearchProps) => {
  const interactiveSarchIcon = searchExpanded ? (
    <SearchIcon isFormSubmit={true} />
  ) : (
    <SearchIcon
      onClickFunction={() => {
        setSearchExpanded && setSearchExpanded(!searchExpanded);
      }}
    />
  );

  return (
    <React.Fragment>
      <form role="search" action="/search/" method="get">
        {searchExpanded && <SearchTextBox />}
        {interactiveSarchIcon}
      </form>
      {searchExpanded && (
        <CloseIcon
          onClickFunction={() => {
            setSearchExpanded && setSearchExpanded(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export const MobileNavigationSearch = () => (
  <div className="mobile-navigation-search-container">
    <form role="search" action="/search/" method="get">
      <SearchTextBox />
      <SearchIcon isFormSubmit={true} />
    </form>
  </div>
);

export const MobileNavigationResponsiveSearch = () => {
  return (
    <SearchIcon
      onClickFunction={() => {
        const menuBar = document.getElementById("lod-menu-search-bar");
        if (menuBar) menuBar.style.display = "block";
        const searchContainer = document.querySelector(
          "#lod-menu .navigation-search-container",
        ) as HTMLElement;
        if (searchContainer) searchContainer.style.display = "none";
      }}
    />
  );
};

export const SearchBar = () => {
  const interactiveSarchIcon = <SearchIcon isFormSubmit={true} />;

  return (
    <form role="search" action="/search/" method="get">
      <SearchTextBox />
      <div style={{ display: "flex" }}>
        {interactiveSarchIcon}
        <CloseIcon
          onClickFunction={() => {
            const menuBar = document.getElementById("lod-menu-search-bar");
            if (menuBar) menuBar.style.display = "none";
            const searchContainer = document.querySelector(
              "#lod-menu .navigation-search-container",
            ) as HTMLElement;
            searchContainer.style.display = "block";
          }}
        />
      </div>
    </form>
  );
};
