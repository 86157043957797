import classnames from "classnames";
import React from "react";

function ErrorRenderer({
  errors,
  field,
  className,
}: {
  errors: any;
  field: string;
  className?: string;
}) {
  return errors && errors.response && errors.response.data[field]
    ? errors.response.data[field].map((error: string, index: number) => (
        <p key={index} className={classnames("error", className)}>
          {error}
        </p>
      ))
    : null;
}

export { ErrorRenderer };

export default React.memo(ErrorRenderer);
