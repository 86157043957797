import React, { InputHTMLAttributes } from "react";
import { WrappedFieldProps } from "redux-form";

const FileInput = (
  props: WrappedFieldProps & InputHTMLAttributes<HTMLInputElement>,
) => (
  <input
    id="custom-file-upload"
    type="file"
    onChange={props.input.onChange}
    disabled={props.disabled}
    value={props.input.value ? props.input.value.name : undefined}
  />
);

export { FileInput };

export default React.memo(FileInput);
